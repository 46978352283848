import { List } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import _ from "lodash";

const ListWrapper = styled(List)`
  padding-bottom: 2rem;

  .ant-list {
    padding: 0 0 !important;
  }

  .ant-list-item {
    background-color: #dcefff ;
    border: 1px solid #c9c9c9;
    border-block-end: none;
    padding: 0 0 !important ;
    min-height: 100% !important;
    display: flex;
    align-items: inherit;
    &:last-child{
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      overflow: hidden;
    }
    @media only screen and (max-width: 1300px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      height: 100% !important;
    }
  }

  .ant-list-item:last-child {
    border-bottom: 1px solid #c9c9c9;
  }

  .ant-list .ant-list-item {
    padding: 0 0 !important;
  }

  .name-courses {
    width: 208px !important;
    /* height: 100% !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    box-sizing: border-box;
    @media only screen and (max-width: 1300px) {
      height: auto !important;
    }

    /* @media only screen and (max-width: 576px) {
      height: 100% !important;
    } */
    span {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 30px;
    }
  }

  .list-action {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1.8rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 52px;
    border-left: 1px solid #c9c9c9;
    background-color: #fafafa ;

    .course-item {
      padding-right: 30px;

      @media only screen and (max-width: 576px) {
      padding-right: 5px;
    }
      /* display: flex;
      align-items: center; */
    }

    input[type="checkbox"] {
      appearance: none;
      background-color: #fff;
      border-radius: 2px;
      display: inline-grid;
      place-content: center;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 20px;
      height: 20px;
      transform: scale(0);
      transform-origin: bottom left;
      background-color: #000;
      clip-path: polygon(39% 82%, 90% 15%, 97% 21%, 40% 96%, 0 65%, 6% 57%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    input[type="checkbox"]:checked {
      background-color: #fff;
      border: 2px solid #ccc;
    }
  }
`;

const Title = styled(List)`
  padding-top: 2rem;
  margin-top: 16px;

  .ant-list {
    padding: 0 0 !important;
  }

  .ant-list-item {
    background-color: #dcefff ;
    border: 1px solid #c9c9c9;
    border-block-end: none;
    padding: 0 0 !important ;
    min-height: 100% !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    @media only screen and (max-width: 1300px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      height: 100% !important;
    }
  }

  .ant-list .ant-list-item {
    padding: 0 0 !important;
  }

  .name-courses {
    width: 208px !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    box-sizing: border-box;
    @media only screen and (max-width: 1300px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      height: 100% !important;
    }
    span {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 30px;
    }
  }

  .list-action {
    width: 100%;
    padding-left: 1.8rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 52px;
    border-left: 1px solid #c9c9c9;
    text-align: center;

    .course-item {
      padding-right: 30px;
      font-weight: bold;

      @media only screen and (max-width: 576px) {
      padding-right: 5px;
    }
      /* display: flex;
      align-items: center; */
    }

    input[type="checkbox"] {
      appearance: none;
      background-color: #fff;
      border-radius: 2px;
      display: inline-grid;
      place-content: center;
      margin-right: 10px;
      width: 28px;
      height: 28px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 20px;
      height: 20px;
      transform: scale(0);
      transform-origin: bottom left;
      background-color: #000;
      clip-path: polygon(39% 82%, 90% 15%, 97% 21%, 40% 96%, 0 65%, 6% 57%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    input[type="checkbox"]:checked {
      background-color: #fff;
      border: 2px solid #ccc;
    }
  }
`;

function ListCourseChecked({ loading, dataSource, dataselectedContentPerson }) {
  const [t] = useTranslation('student');
  let title = [{
    name: "",
    itemChecked: "共通テスト",
    itemCheckedPerson: "二次・個別"
  }]
  
  const listCourseChecked = useMemo(() => {
    let arr = []
    if(!_.isEmpty(dataSource)){
      for (const [key, value] of Object.entries(dataSource)) {
        arr.push({
          name: key,
          itemChecked: value
        }) 
      }
      if(!_.isEmpty(dataselectedContentPerson)){
        for (const [key, value] of Object.entries(dataselectedContentPerson)) {
          if (arr.every(item => item.name !== key)) {
            arr.push({
              name: key,
              itemCheckedPerson: value
            }) 
          }
          arr.forEach(data => {
            if (data?.name === key) {
              Object.assign(data, { itemCheckedPerson: value });
            }
          });
        }
      }
    }
    return arr
  }, [dataSource, dataselectedContentPerson])
  return (
    <>
      <Title
        // loading={loading}
        itemLayout="horizontal"
        dataSource={title}
        renderItem={(item) => (
          <List.Item>
            <div className="name-courses">
              <span>{item?.name}</span>
            </div>
            <div className="list-action">
              <label  className="course-item">
                {item?.itemChecked}
              </label>
            </div>
            <div className="list-action">
              <label  className="course-item">
                {item?.itemCheckedPerson}
              </label>
            </div>
          </List.Item>
        )}
      />
      <ListWrapper
        loading={loading}
        itemLayout="horizontal"
        dataSource={listCourseChecked}
        locale={{ emptyText:t("no_data")}}
        renderItem={(item) => (
          <List.Item>
            <div className="name-courses">
              <span>{item?.name}</span>
            </div>
            <div className="list-action">
                <label  className="course-item">
                {item?.itemChecked && item?.itemChecked.join(" 、")}
                </label>
            </div>
            <div className="list-action">
              <label  className="course-item">
                {item?.itemCheckedPerson && item?.itemCheckedPerson.join(" 、")}
              </label>
            </div>
          </List.Item>
        )}
      />
    </>
  );
}

export default ListCourseChecked;